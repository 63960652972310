import React, { Component } from 'react'

class Loader extends Component {
    
    render() {
        return (
            <div className="sk-spinner sk-spinner-double-bounce">
                <div className="sk-double-bounce1"></div>
                <div className="sk-double-bounce2"></div>
            </div>
        )
    }
}

export default Loader
